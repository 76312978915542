import { gql } from "@apollo/client";

export default gql`
  mutation CreateSchoolMonthlyEvaluation(
    $title: String!
    $description: String!
    $schoolId: Float!
    $evaluationDate: String!
  ) {
    postSchoolMonthlyEvaluations(
      newSchoolMonthlyEvaluationInput: {
        title: $title
        description: $description
        schoolId: $schoolId
        evaluationDate: $evaluationDate
      }
    ) {
      id
    }
  }
`;
