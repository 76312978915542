import { gql } from "@apollo/client";

export default gql`
  query GetSchoolMonthlyEvaluationResults($schoolId: Float!, $date: String!) {
    schoolMonthlyEvaluationsResults(date: $date, schoolId: $schoolId) {
      id
      question
      answerCount {
        answerType
        numberOfAnswers
      }
    }
  }
`;
