import { gql } from "@apollo/client";

export default gql`
  query GetLicenseKeys($schoolId: Float!) {
    schoolLicenseKeys(id: $schoolId) {
      id
      key
      schoolId
      expirationDate
      userId
      user {
        id
        email
      }
    }
  }
`;
