import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./graphql/client";
import {Buffer} from 'buffer';

if(!window.Buffer) window.Buffer = Buffer;

ReactDOM.render(
  <Suspense fallback="loading">
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
