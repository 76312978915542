import React, { useContext, useState } from "react";
import { Fonts } from "../../styles";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BaseInputField from "../../components/Inputs/BaseInput";
import PropTypes from "prop-types";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import TransparentButton from "../../components/Buttons/TransparentButton";
import { useMutation } from "@apollo/client";
import { CREATE_USER } from "../../graphql/mutations";
import { GET_LICENSE_KEYS } from "../../graphql/queries";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import { AuthContext } from "../../context/auth-context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  modalContainer: {
    padding: 40,
    width: 500,
  },
  title: {
    fontSize: Fonts.SIZES.EXTRA_TITLE_TEXT,
    fontWeight: 600,
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
  },
  disabledButton: {
    opacity: 0.5,
    color: "rgba(255, 255, 255, 1)!important",
  },
}));

const AddNewUserModal = ({ onSave }) => {
  const classes = useStyles();
  const [studentEmail, setStudentEmail] = useState("");
  const { user } = useContext(AuthContext) || {};
  const { t } = useTranslation();
  const [saveUserMutation, { loading }] = useMutation(CREATE_USER, {
    refetchQueries: [GET_LICENSE_KEYS, "GetLicenseKeys"],
  });

  const saveUser = async () => {
    try {
      await saveUserMutation({
        variables: {
          email: studentEmail,
          schoolId: user?.school?.id,
        },
      });
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
    onSave();
  };

  return (
    <Box className={classes.modalContainer}>
      <Typography className={classes.title}>
        {t("users.addNewButton")}
      </Typography>
      <BaseInputField
        id="add-new-student"
        onChange={(event) => setStudentEmail(event.target.value)}
        value={studentEmail}
        label={t("users.schoolEmailLabel")}
      />
      <Box className={classes.buttonContainer}>
        <TransparentButton onClick={onSave}>
          {t("users.cancel")}
        </TransparentButton>
        <PrimaryButton
          onClick={saveUser}
          disabled={loading}
          disabledClass={classes.disabledButton}
        >
          {t("users.save")}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

AddNewUserModal.propTypes = {
  onSave: PropTypes.func,
};

export default AddNewUserModal;
