import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  subMenuContainer: {
    position: "absolute",
    bottom: -40,
    left: -170,
    backgroundColor: Colors.COLOR_WHITE,
    overflow: "visible",
    width: 200,
    zIndex: 2,
    boxShadow: "0px 3px 6px #00000029",
  },
  menuRow: {
    padding: "10px 20px",
    textAlign: "left",
  },
  menuText: {
    color: Colors.COLOR_RED,
    fontSize: Fonts.SIZES.MESSAGE_TEXT,
  },
}));

const UserSubMenu = ({ onDeleteClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.subMenuContainer}>
      <Box className={classes.menuRow} onClick={onDeleteClick}>
        <Typography className={classes.menuText}>
          {t("users.deleteStudent")}
        </Typography>
      </Box>
    </Box>
  );
};

UserSubMenu.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
};

export default UserSubMenu;
