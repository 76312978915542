import React, { useContext, useEffect, useState } from "react";
import UserTable from "./UserTable";
import { Colors, Fonts } from "../../styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import People from "../../components/Icons/People";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BaseInputField from "../../components/Inputs/BaseInput";
import Dialog from "@material-ui/core/Dialog";
import AddNewUserModal from "./AddNewUserModal";
import DeleteUserModal from "./DeleteUserModal";
import UserTablePagination from "./UserTablePagination";
import { resultsPerPage } from "../../constants/pagination";
import { useQuery } from "@apollo/client";
import { GET_LICENSE_KEYS } from "../../graphql/queries";
import { AuthContext } from "../../context/auth-context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  headerContainer: {
    margin: "40px 0",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: 500,
    "& div": {
      marginTop: 0,
    },
  },
  contentContainer: {
    padding: "1rem 5rem",
    flex: 1,
    maxWidth: "70vw",
  },
  userContainer: {
    marginLeft: 300,
  },
}));

const Users = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUserIdForDelete, setSelectedUserIdForDelete] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [licenseKeyData, setLicenseKeyData] = useState([]);
  const [originalLicenseKeyData, setOriginalLicenseKeyData] = useState([]);
  const [maxPages, setMaxPages] = useState(0);
  const { user } = useContext(AuthContext) || {};
  const { t } = useTranslation();
  const { refetch: refetchLicenseKeys, data: licenseData } = useQuery(
    GET_LICENSE_KEYS,
    {
      variables: {
        schoolId: user?.school?.id,
      },
    }
  );

  useEffect(() => {
    document.title = t("users.title");
  }, [t]);

  useEffect(() => {
    setOriginalLicenseKeyData(
      licenseData
        ? JSON.parse(JSON.stringify(licenseData.schoolLicenseKeys))
        : []
    );
    setLicenseKeyData(
      licenseData
        ? JSON.parse(JSON.stringify(licenseData.schoolLicenseKeys))
        : []
    );
    setMaxPages(licenseData ? licenseData.schoolLicenseKeys.length : 1);
    setCurrentPage(1);
  }, [licenseData]);

  useEffect(() => {
    const startIndex =
      currentPage === 1 ? 0 : (currentPage - 1) * resultsPerPage;
    const endIndex =
      currentPage === 1 ? resultsPerPage : currentPage * resultsPerPage;
    if (!searchValue.trim()) {
      setLicenseKeyData(
        JSON.parse(
          JSON.stringify(originalLicenseKeyData.slice(startIndex, endIndex))
        )
      );
      setMaxPages(Math.ceil(originalLicenseKeyData.length / resultsPerPage));
    } else {
      const searchedValues = originalLicenseKeyData.filter((data) => {
        return (
          data.key.indexOf(searchValue) > -1 ||
          (data.user ? data.user.email.indexOf(searchValue) > -1 : false)
        );
      });
      setLicenseKeyData(searchedValues.slice(startIndex, endIndex));
      setMaxPages(Math.ceil(searchedValues.length / resultsPerPage));
    }
  }, [currentPage, originalLicenseKeyData, searchValue, maxPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const handlePrevPageClick = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  return (
    <Box className={classes.userContainer}>
      <Grid container>
        <Box className={classes.contentContainer}>
          <Grid container className={classes.headerContainer}>
            <People
              height={40}
              width={40}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.title}>
              {t("users.header")}
            </Typography>
          </Grid>
          <Box className={classes.filterContainer}>
            <Box className={classes.inputContainer}>
              <BaseInputField
                id="search-value"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                placeholder={t("users.searchPlaceholder")}
              />
            </Box>
            <PrimaryButton onClick={() => setShowAddUserModal(true)}>
              {t("users.addNewButton")}
            </PrimaryButton>
          </Box>
          <UserTable
            results={licenseKeyData.map((licenseKey) => {
              return {
                id: licenseKey.id,
                licenseKey: licenseKey.key,
                email: licenseKey?.user?.email || "",
                registered: !!licenseKey.userId,
                userId: licenseKey.userId,
              };
            })}
            onDeleteClick={(userId) => {
              setSelectedUserIdForDelete(String(userId));
              setShowDeleteUserModal(true);
            }}
          />
          <UserTablePagination
            prevDisabled={currentPage === 1}
            nextDisabled={currentPage >= maxPages}
            onPrevPageClick={handlePrevPageClick}
            onNextPageClick={handleNextPageClick}
          />
        </Box>
      </Grid>
      <Dialog
        onClose={() => setShowAddUserModal(false)}
        aria-labelledby="simple-dialog-title"
        open={showAddUserModal}
      >
        <AddNewUserModal
          onSave={async () => {
            await refetchLicenseKeys();
            setShowAddUserModal(false);
          }}
        />
      </Dialog>
      <Dialog
        onClose={() => setShowDeleteUserModal(false)}
        aria-labelledby="simple-dialog-title"
        open={showDeleteUserModal}
      >
        <DeleteUserModal
          onDelete={async () => {
            await refetchLicenseKeys();
            setShowDeleteUserModal(false);
          }}
          selectedUserIdForDelete={selectedUserIdForDelete}
        />
      </Dialog>
    </Box>
  );
};

export default Users;
