import { gql } from "@apollo/client";

export default gql`
  query CheckSchoolMonthlyEvaluationAvailable(
    $date: String!
    $schoolId: Float!
  ) {
    schoolMonthlyEvaluations(date: $date, schoolId: $schoolId) {
      hasEvaluation
    }
  }
`;
