import { gql } from "@apollo/client";

export default gql`
  mutation RefreshToken($refreshToken: String!) {
    postTokenRefresh(tokenRefreshInput: { refreshToken: $refreshToken }) {
      accessToken
      expiresAt
    }
  }
`;
