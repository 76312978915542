const FACES = {
  Quantico: "Quantico-Regular",
  QuanticoBold: "Quantico-Bold",
};

const SIZES = {
  EXTRA_TITLE_TEXT: 35,
  TITLE_TEXT: 30,
  SUBTITLE_TEXT: 25,
  LARGE_LINK_TEXT: 23,
  LARGE_DESCRIPTION_TEXT: 20,
  TABLE_HEADING_TEXT: 19,
  LOGIN_LABEL: 18,
  MEDIUM_DESCRIPTION_TEXT: 17,
  SMALL_DESCRIPTION_TEXT: 18,
  MESSAGE_TEXT: 16,
  DATE_TEXT: 15,
  MESSAGE_INFO_TEXT: 14,
};

const WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold",
};

const FONT_DATA = {
  SIZES,
  WEIGHTS,
  FACES,
};

export default FONT_DATA;
