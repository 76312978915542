import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { Box, Typography } from "@material-ui/core";
import WhistleBlower from "../../components/Icons/WhistleBlower";
import People from "../../components/Icons/People";
import Stats from "../../components/Icons/Stats";
import Home from "../../components/Icons/Home";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  sidebar: {
    borderRight: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    borderBottom: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    padding: 30,
    minHeight: "100vh",
    backgroundColor: Colors.COLOR_WHITE,
    position: "absolute",
    top: 94,
    left: 0,
    width: 250,
  },
  sidebarItem: {
    display: "flex",
    alignItems: "center",
    marginTop: 25,
  },
  menuText: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    marginLeft: 15,
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Box className={classes.sidebar}>
      <NavLink to="/home">
        <Box className={classes.sidebarItem}>
          <Home
            height={24}
            width={24}
            color={
              location.pathname === "/home"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.home")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/survey">
        <Box className={classes.sidebarItem}>
          <Stats
            height={24}
            width={24}
            color={
              location.pathname === "/survey"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.monthlyEvaluation")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/bulletin">
        <Box className={classes.sidebarItem}>
          <WhistleBlower
            height={24}
            width={24}
            color={
              location.pathname === "/bulletin"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.bulletin1")}/
            <br />
            {t("sidebar.bulletin2")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/users">
        <Box className={classes.sidebarItem}>
          <People
            height={24}
            width={24}
            color={
              location.pathname === "/users"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.users")}
          </Typography>
        </Box>
      </NavLink>
    </Box>
  );
};

export default Sidebar;
