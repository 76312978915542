import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import { v4 as uuidv4 } from "uuid";
import { Box, InputLabel } from "@material-ui/core";
import { Colors, Fonts } from "../../styles";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    color: Colors.COLOR_LABEL,
    marginBottom: 10,
  },
  dropDown: {
    border: `1px solid ${Colors.COLOR_PRIMARY_BUTTON}`,
    padding: "7px 25px 7px 25px",
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    marginBottom: 0,
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
    "& .MuiNativeSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& svg": {
      right: 10,
      color: Colors.COLOR_PRIMARY_BUTTON,
    },
  },
}));

const Dropdown = (props) => {
  const classes = useStyles(props);
  const { value, items, onChange, labelValue } = props;
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Box>
      <InputLabel
        className={classes.label}
        htmlFor="demo-customized-select-native"
      >
        {labelValue}
      </InputLabel>
      <NativeSelect
        id="demo-customized-select-native"
        value={value}
        onChange={handleChange}
        className={classes.dropDown}
      >
        {items.map((item) => (
          <option key={uuidv4()} value={item}>
            {item.toLowerCase()}
          </option>
        ))}
      </NativeSelect>
    </Box>
  );
};

export default Dropdown;
