import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import evaluationValues from "../../constants/evaluationValues";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  tableRowContainer: {
    display: "flex",
    padding: "10px 10px 10px 40px",
    backgroundColor: Colors.COLOR_WHITE,
    "&:nth-child(even)": {
      backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
    },
    "& p": {
      fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
    },
  },
  firstColumn: {
    width: 150,
  },
  column: {
    width: "calc(50% - 150px)",
  },
  resultText: {
    "&:not(:first-child)": {
      marginTop: 5,
    },
  },
}));

const SurveyTableRow = ({ questionNumber, question, questionResults }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.tableRowContainer}>
      <Box className={classes.firstColumn}>
        <Typography>{questionNumber}</Typography>
      </Box>
      <Box className={classes.column}>
        <Typography>{question}</Typography>
      </Box>
      <Box className={classes.column}>
        {questionResults?.map((result) => (
          <Typography className={classes.resultText} key={uuidv4()}>
            {t(evaluationValues[result.answerType])} - ({result.numberOfAnswers}{" "}
            {t("monthlyEvaluation.resultText")})
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

SurveyTableRow.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  questionResults: PropTypes.arrayOf(
    PropTypes.shape({
      answerType: PropTypes.string,
      numberOfAnswers: PropTypes.number,
    })
  ).isRequired,
};

export default SurveyTableRow;
