export const getLocalStorageValue = (key, defaultValue) => {
  if (!JSON.parse(localStorage.getItem(key))) {
    return defaultValue;
  }
  return JSON.parse(localStorage.getItem(key));
};

export const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorageValue = (key) => {
  localStorage.removeItem(key);
};

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};
