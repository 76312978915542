import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import mobile from "../../assets/images/bulletin-board-preview.png";
import CustomTab from "../../components/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { Box, ButtonBase, Grid, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import BaseInputField from "../../components/Inputs/BaseInput";
import Plus from "../../components/Icons/Plus";
import Close from "../../components/Icons/Close";
import ProgressCard from "../../components/Progress/ProgressCard";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_SCHOOL_BULLETIN_BOARD_QUESTION } from "../../graphql/mutations";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import { GET_BULLETIN_RESULTS } from "../../graphql/queries";
import WhistleBlower from "../../components/Icons/WhistleBlower";
import { AuthContext } from "../../context/auth-context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  descriptionContainer: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    lineHeight: 1.4,
    maxWidth: 500,
    paddingTop: 25,
  },
  buttonContainer: {
    marginTop: 50,
  },
  disabledButton: {
    opacity: 0.5,
    color: "rgba(255, 255, 255, 1)!important",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
  },
  titleDescription: {
    marginTop: 30,
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
  },
  icon: {
    height: 33,
    width: 23,
  },
  headerContainer: {
    margin: "40px 0",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: -17,
  },
  formContainer: {
    marginTop: 30,
  },
  answerAlternativesLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
  tabFormContainer: {
    width: (props) => (props.activeTab === 1 ? "100%" : "50%"),
    maxWidth: (props) => (props.activeTab === 1 ? "100%" : 700),
    minWidth: (props) => (props.activeTab === 1 ? "100%" : 300),
  },
  answerAlternativesContainer: {
    marginTop: 30,
  },
  addNewAnswerButton: {
    marginTop: (props) => (props.numberOfAnswerAlternatives > 0 ? 30 : 0),
  },
  addNewAnswerButtonText: {
    color: Colors.COLOR_PRIMARY_BUTTON,
    fontSize: Fonts.SIZES.LARGE_LINK_TEXT,
    marginLeft: 30,
  },
  answerInputContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  closeIcon: {
    marginLeft: 15,
    cursor: "pointer",
  },
  mobilePreviewContainer: {
    textAlign: "center",
    marginLeft: 200,
    paddingTop: 20,
  },
  mobilePreviewText: {
    marginBottom: 10,
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    fontStyle: "italic",
  },
  resultContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  contentContainer: {
    display: "flex",
    padding: "1rem 5rem",
    flex: 1,
    marginLeft: 300,
  },
}));

const Bulletin = (props) => {
  const [activeTab, setActiveTab] = useState({
    tab: 0,
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [answerAlternatives, setAnswerAlternatives] = useState({});
  const [formValid, setFormValid] = useState(false);
  const classes = useStyles({
    numberOfAnswerAlternatives: Object.keys(answerAlternatives).length,
    activeTab: activeTab.tab,
    ...props,
  });
  const { user } = useContext(AuthContext) || {};
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("bulletin.title");
  }, [t]);

  const handleTabChange = (event, tab) => {
    setActiveTab({ tab });
  };

  const handleAnswerAlternativeInputChange = (event) => {
    setAnswerAlternatives({
      ...answerAlternatives,
      [event.target.name]: {
        answer: event.target.value,
      },
    });
  };

  const handleAddNewAnswerAlternative = () => {
    setAnswerAlternatives({
      ...answerAlternatives,
      [uuidv4()]: {
        answer: "",
      },
    });
  };

  const handleRemoveAnswerAlternative = (id) => {
    const newAnswerAlternatives = { ...answerAlternatives };
    delete newAnswerAlternatives[id];
    setAnswerAlternatives({
      ...newAnswerAlternatives,
    });
  };

  useEffect(() => {
    const answerAlternativesTextArray = Object.keys(answerAlternatives).map(
      (id) => answerAlternatives[id].answer
    );
    setFormValid(
      title.length > 0 &&
        description.length > 0 &&
        Object.keys(answerAlternatives).length > 0 &&
        answerAlternativesTextArray.every((text) => text.length > 0)
    );
  }, [title, description, answerAlternatives]);

  const [createSchoolBulletinBoardQuestion] = useMutation(
    CREATE_SCHOOL_BULLETIN_BOARD_QUESTION,
    {
      refetchQueries: [GET_BULLETIN_RESULTS, "GetBulletinBoardResults"],
    }
  );

  const createBulletinBoardQuestion = async () => {
    try {
      await createSchoolBulletinBoardQuestion({
        variables: {
          question: title,
          description: description,
          schoolId: user?.school?.id,
          userId: user?.id,
          answers: Object.keys(answerAlternatives).map(
            (id) => answerAlternatives[id].answer
          ),
        },
      });
      setTitle("");
      setDescription("");
      setAnswerAlternatives({});
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  const bulletinResults = useQuery(GET_BULLETIN_RESULTS, {
    variables: {
      schoolId: user?.school?.id,
    },
  });

  return (
    <Grid className={classes.contentContainer}>
      <Box className={classes.tabFormContainer}>
        <Box className={classes.headerContainer}>
          <Box className={classes.titleContainer}>
            <WhistleBlower
              height={40}
              width={40}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.title}>
              {t("bulletin.header")}
            </Typography>
          </Box>
          <Typography className={classes.titleDescription}>
            {t("bulletin.headerText")}
          </Typography>
        </Box>
        <CustomTab
          activeTab={activeTab.tab}
          onTabChange={handleTabChange}
          tabLabels={[t("bulletin.sendTab"), t("bulletin.resultTab")]}
        />
        {activeTab.tab === 0 ? (
          <Box>
            <Box className={classes.formContainer}>
              <BaseInputField
                id="title"
                label={t("bulletin.titleLabel")}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
              <BaseInputField
                id="description"
                label={t("bulletin.descriptionLabel")}
                multiline={true}
                rows={8}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
              <Box className={classes.answerAlternativesContainer}>
                <Typography className={classes.answerAlternativesLabel}>
                  {t("bulletin.answerAlternativesLabel")}
                </Typography>
                {Object.keys(answerAlternatives).map((id) => {
                  return (
                    <Box className={classes.answerInputContainer} key={id}>
                      <BaseInputField
                        id={id}
                        value={answerAlternatives[id].answer}
                        onChange={(event) =>
                          handleAnswerAlternativeInputChange(event)
                        }
                        rowMargin={0}
                      />
                      <Close
                        className={classes.closeIcon}
                        height={40}
                        width={40}
                        color={Colors.COLOR_RED}
                        onClick={() => handleRemoveAnswerAlternative(id)}
                      />
                    </Box>
                  );
                })}
                <ButtonBase
                  onClick={handleAddNewAnswerAlternative}
                  className={classes.addNewAnswerButton}
                  disableRipple={true}
                >
                  <Plus
                    height={20}
                    width={20}
                    color={Colors.COLOR_PRIMARY_BUTTON}
                  />
                  <Typography className={classes.addNewAnswerButtonText}>
                    {t("bulletin.addAnswerButton")}
                  </Typography>
                </ButtonBase>
              </Box>
            </Box>
            <Box className={classes.buttonContainer}>
              <PrimaryButton
                onClick={createBulletinBoardQuestion}
                disabled={!formValid}
                disabledClass={classes.disabledButton}
              >
                {t("bulletin.sendButton")}
              </PrimaryButton>
            </Box>
          </Box>
        ) : null}
        {activeTab.tab === 1 ? (
          <Box className={classes.resultContainer}>
            {bulletinResults?.data?.bulletinBoardQuestionsResult?.map(
              (question) => {
                return (
                  <ProgressCard
                    key={question.id}
                    question={question.question}
                    numberOfAnswers={question.totalAnswers}
                    toDate={question.toDate || ""}
                    fromDate={question.fromDate || ""}
                    answers={question.results.map((result) => {
                      return {
                        answer: result.answer,
                        percentage: result.percentageOfVotes,
                      };
                    })}
                  />
                );
              }
            )}
          </Box>
        ) : null}
      </Box>
      {activeTab.tab === 0 ? (
        <Box className={classes.mobilePreviewContainer}>
          <Typography className={classes.mobilePreviewText}>
            {t("bulletin.preview")}
          </Typography>
          <img src={mobile} alt="mobile-preview" />
        </Box>
      ) : null}
    </Grid>
  );
};

export default Bulletin;
