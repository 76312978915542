import { Box, ButtonBase, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import PropTypes from "prop-types";
import MoreMenu from "../../components/Icons/MoreMenu";
import UserSubMenu from "./UserSubMenu";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  tableRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 10px 10px 40px",
    backgroundColor: Colors.COLOR_WHITE,
    "&:nth-child(even)": {
      backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
    },
  },
  text: {
    fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
  },
  column: {
    flex: "30%",
  },
  columnSmall: {
    display: "flex",
    flex: "10%",
    justifyContent: "flex-end",
    position: "relative",
  },
}));

const UserTableRow = ({ licenseKey, email, registered, onDeleteClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showSubMenu, setShowSubMenu] = useState(false);
  return (
    <Box className={classes.tableRowContainer}>
      <Box className={classes.column}>
        <Typography className={classes.text}>{licenseKey}</Typography>
      </Box>
      <Box className={classes.column}>
        <Typography className={classes.text}>{email}</Typography>
      </Box>
      <Box className={classes.column}>
        <Typography className={classes.text}>
          {registered ? t("users.registered") : t("users.notRegistered")}
        </Typography>
      </Box>
      <Box className={classes.columnSmall}>
        <ButtonBase
          onClick={() => setShowSubMenu((prevState) => !prevState)}
          disableRipple={true}
        >
          {registered && (
            <MoreMenu
              height={30}
              width={30}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
          )}
          {showSubMenu && <UserSubMenu onDeleteClick={onDeleteClick} />}
        </ButtonBase>
      </Box>
    </Box>
  );
};

UserTableRow.propTypes = {
  licenseKey: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  registered: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default UserTableRow;
