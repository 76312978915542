import PropTypes from "prop-types";

const WhistleBlower = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.976 24.976"
      fill={color}
    >
      <defs>
        <clipPath id="a">
          <circle
            className="a"
            cx="12.488"
            cy="12.488"
            r="12.488"
            transform="translate(0 0)"
          />
        </clipPath>
      </defs>
      <g transform="translate(-10.84 -7.237)">
        <g className="b" transform="translate(10.84 7.237)">
          <path
            className="c"
            d="M510.689,201.351a1.122,1.122,0,0,1,1.121,1.121,17.3,17.3,0,0,0-.839,3.178c0,.6.839,1.579.839,1.579v36.423a1.122,1.122,0,0,1-1.121,1.122h-2.243a1.122,1.122,0,0,1-1.121-1.122V207.23s.839-.983.839-1.579a17.3,17.3,0,0,0-.839-3.178,1.122,1.122,0,0,1,1.121-1.121Zm-1.121,14.939c.507,0,1.774-2.151,1.521-2.581s-2.788-.43-3.042,0S509.06,216.291,509.567,216.291Z"
            transform="translate(-496.84 -198.164)"
          />
        </g>
      </g>
    </svg>
  );
};

WhistleBlower.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default WhistleBlower;
