import Login from "./containers/Login";
import Home from "./containers/Home";
import Survey from "./containers/Survey";
import Bulletin from "./containers/Bulletin";
import Users from "./containers/Users";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  deleteLocalStorage,
  getLocalStorageValue,
  setLocalStorageValue,
} from "./utils/localStorageUtils";
import localStorageKeys from "./constants/localStorageKeys";
import Layout from "./containers/Layout/Layout";
import { useIdleTimer } from "react-idle-timer";
import "./i18n/config";

function App() {
  const defaultAuthContext = useContext(AuthContext);
  const [auth, setAuth] = useState(defaultAuthContext);
  const [inactive, setInactive] = useState(false);

  console.log("App sees auth as:", auth);

  const onLogin = (tokenObj) => {
    console.log("~~ onLogin invoked! tokenObj =", tokenObj);
    setLocalStorageValue(localStorageKeys.ACCESS_TOKEN, tokenObj);
    setAuth(tokenObj);
  };

  const onLogOut = () => {
    deleteLocalStorage(localStorageKeys.ACCESS_TOKEN);
    setAuth(null);
  };

  useEffect(() => {
    const stored = getLocalStorageValue(localStorageKeys.ACCESS_TOKEN, null);
    // stored kan vara null om inget finns, eller { token: "eyJhbGci..." } om inloggad
    setAuth(stored);
  }, []);

  useEffect(() => {
    if (inactive) {
      setInactive(false);
      onLogOut();
    }
  }, [inactive]);

  const handleOnIdle = useCallback(() => {
    setInactive(true);
  }, []);

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => handleOnIdle(),
    debounce: 500,
    crossTab: true,
  });

  return (
    <AuthContext.Provider value={auth}>
      <HashRouter>
        <Layout onLogOut={onLogOut}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Login onLoginComplete={onLogin} />}
            />
            <Route
              path="/home"
              component={() => (auth ? <Home /> : <Redirect to="/" />)}
            />
            <Route
              path="/survey"
              component={() => (auth ? <Survey /> : <Redirect to="/" />)}
            />
            <Route
              path="/bulletin"
              component={() => (auth ? <Bulletin /> : <Redirect to="/" />)}
            />
            <Route
              path="/users"
              component={() => (auth ? <Users /> : <Redirect to="/" />)}
            />
          </Switch>
        </Layout>
      </HashRouter>
    </AuthContext.Provider>
  );
}

export default App;
