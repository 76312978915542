import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import BaseButton from "../BaseButton";
import Fonts from "../../../styles/Fonts";
import { Colors } from "../../../styles";

const useStyles = makeStyles(() => ({
  button: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    padding: "25px 0",
    backgroundColor: "transparent",
    color: Colors.COLOR_PRIMARY_BUTTON,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    opacity: (props) => (props.disabled ? 0.5 : 1),
  },
}));

const TransparentButton = (props) => {
  const classes = useStyles(props);
  return (
    <BaseButton
      disabled={props.disabled}
      className={classNames(classes.button, props.className)}
      width={props.width}
      onClick={props.onClick}
      size={props.size}
      style={props.style}
      disabledClass={props.disabledClass}
    >
      {props.children}
    </BaseButton>
  );
};

export default TransparentButton;
