import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import { Colors } from "../../styles";
import Fonts from "../../styles/Fonts";

const useStyles = makeStyles(() => ({
  bar: {
    height: 40,
    border: `1px solid ${Colors.COLOR_GRAY}`,
  },
  barFilled: {
    backgroundColor: Colors.COLOR_PROGRESS_BAR_GREEN,
  },
  barNotFilled: {
    backgroundColor: Colors.COLOR_WHITE,
  },
  container: {
    position: "relative",
  },
  labelBase: {
    position: "absolute",
    top: 7,
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
  },
  labelLeft: {
    left: 15,
  },
  labelRight: {
    right: 15,
    color: Colors.COLOR_PRIMARY_BUTTON,
  },
}));

function LinearProgressWithLabel({ value, label }) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <LinearProgress
        variant="determinate"
        value={value}
        classes={{
          root: classes.bar,
          bar: classes.barFilled,
          colorPrimary: classes.barNotFilled,
        }}
      />
      <Typography className={classNames(classes.labelBase, classes.labelLeft)}>
        {label}
      </Typography>
      <Typography
        className={classNames(classes.labelBase, classes.labelRight)}
      >{`${Math.round(value)}%`}</Typography>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default LinearProgressWithLabel;
