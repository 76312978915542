import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Dropdown from "../../components/Dropdown";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import Stats from "../../components/Icons/Stats";
import { CREATE_SCHOOL_MONTHLY_EVALUATION } from "../../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import {
  GET_MONTHLY_EVALUATION_RESULTS,
  GET_SCHOOL_HAS_ACTIVE_MONTHLY_EVALUATION,
} from "../../graphql/queries";
import CustomTab from "../../components/Tabs";
import { allMonthsEnglish, allMonthsSwedish } from "../../constants/months";
import { getMonth, getYear } from "date-fns";
import SurveyTable from "./SurveyTable";
import { AuthContext } from "../../context/auth-context";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MonthlyEvaluationPDF from "../../components/pdf/monthlyEvaluationPDF";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  descriptionContainer: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    lineHeight: 1.4,
    maxWidth: 500,
    paddingTop: 25,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
  buttonMessage: {
    marginTop: 25,
  },
  disabledButton: {
    opacity: 0.5,
    color: "rgba(255, 255, 255, 1)!important",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  icon: {
    height: 33,
    width: 23,
  },
  headerContainer: {
    margin: "40px 0",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: -50,
  },
  button: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    marginLeft: 30,
  },
  contentContainer: {
    flex: 1,
    padding: "1rem 5rem",
    marginLeft: 300,
  },
}));

const Survey = (props) => {
  const classes = useStyles(props);
  const [activeTab, setActiveTab] = useState({
    tab: 0,
  });
  const { t, i18n } = useTranslation();
  const activeLang = i18n.language;
  const [currentDateString] = useState(new Date().toISOString());
  const [selectedMonth, setSelectedMonth] = useState();
  const { user } = useContext(AuthContext) || {};

  useEffect(() => {
    document.title = t("monthlyEvaluation.title");
    setSelectedMonth(
      activeLang === "sv"
        ? allMonthsSwedish[getMonth(new Date())]
        : allMonthsEnglish[getMonth(new Date())]
    );
  }, [activeLang, t]);

  const handleTabChange = (event, tab) => {
    setActiveTab({ tab });
  };

  const monthlyEvaluationResults = useQuery(GET_MONTHLY_EVALUATION_RESULTS, {
    fetchPolicy: "no-cache",
    variables: {
      schoolId: user?.school?.id,
      date: new Date(
        getYear(new Date()),
        activeLang === "sv"
          ? allMonthsSwedish.indexOf(selectedMonth)
          : allMonthsEnglish.indexOf(selectedMonth),
        2
      ).toISOString(),
    },
  });

  const monthlyEvaluationStatus = useQuery(
    GET_SCHOOL_HAS_ACTIVE_MONTHLY_EVALUATION,
    {
      variables: {
        date: currentDateString,
        schoolId: user?.school?.id,
      },
    }
  );

  const [createSchoolMonthlyEvaluation] = useMutation(
    CREATE_SCHOOL_MONTHLY_EVALUATION,
    {
      refetchQueries: [
        GET_SCHOOL_HAS_ACTIVE_MONTHLY_EVALUATION,
        "CheckSchoolMonthlyEvaluationAvailable",
      ],
    }
  );

  const createEvaluation = async () => {
    try {
      await createSchoolMonthlyEvaluation({
        variables: {
          title: "Måendeenkät",
          description: "Här är månadens måendeenkät!",
          schoolId: user?.school?.id,
          evaluationDate: currentDateString,
        },
      });
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  return (
    <Box className={classes.contentContainer}>
      <Box>
        <Grid container className={classes.headerContainer}>
          <Stats height={33} width={23} color={Colors.COLOR_PRIMARY_BUTTON} />
          <Typography className={classes.title}>
            {t("monthlyEvaluation.header")}
          </Typography>
        </Grid>
        <CustomTab
          activeTab={activeTab.tab}
          onTabChange={handleTabChange}
          tabLabels={[
            t("monthlyEvaluation.sendTab"),
            t("monthlyEvaluation.resultTab"),
          ]}
        />
        {activeTab.tab === 0 ? (
          <Box className={classes.descriptionContainer}>
            <p>{t("monthlyEvaluation.description")}</p>
            <Box className={classes.buttonContainer}>
              <Grid container justifyContent="center">
                <PrimaryButton
                  onClick={createEvaluation}
                  disabled={
                    monthlyEvaluationStatus?.data?.schoolMonthlyEvaluations
                      ?.hasEvaluation
                  }
                  disabledClass={classes.disabledButton}
                >
                  {t("monthlyEvaluation.buttonText")}
                </PrimaryButton>
                {monthlyEvaluationStatus?.data?.schoolMonthlyEvaluations
                  ?.hasEvaluation && (
                  <Typography className={classes.buttonMessage}>
                    {t("monthlyEvaluation.validationText")}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Box>
        ) : null}
        {activeTab.tab === 1 ? (
          <Box className={classes.filterContainer}>
            <Box>
              <Dropdown
                labelValue={t("monthlyEvaluation.monthSelector")}
                value={selectedMonth}
                items={
                  activeLang === "sv" ? allMonthsSwedish : allMonthsEnglish
                }
                onChange={(value) => setSelectedMonth(value)}
              />
            </Box>
            <Box>
              <PDFDownloadLink
                document={
                  <MonthlyEvaluationPDF
                    results={
                      monthlyEvaluationResults?.data
                        ?.schoolMonthlyEvaluationsResults
                    }
                    month={selectedMonth}
                  />
                }
                fileName={`${t(
                  "monthlyEvaluation.header"
                )}_${selectedMonth}.pdf`}
              >
                {() => (
                  <PrimaryButton
                    className={classes.button}
                    disabledClass={classes.disabledButton}
                  >
                    {t("monthlyEvaluation.pdfButton")}
                  </PrimaryButton>
                )}
              </PDFDownloadLink>
            </Box>
          </Box>
        ) : null}
        {activeTab.tab === 1 ? (
          <SurveyTable
            results={
              monthlyEvaluationResults?.data?.schoolMonthlyEvaluationsResults
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Survey;
