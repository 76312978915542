import PropTypes from "prop-types";

const Stats = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.107 33"
      fill={color}
    >
      <g transform="translate(-31.331 -33.695)">
        <path d="M42.885,33.695a2.271,2.271,0,0,0-2.257,2.04H36.865a1.062,1.062,0,0,0-1.058,1.071V37.6h-.049v.373A1.143,1.143,0,0,0,36.9,39.118H48.832a1.143,1.143,0,0,0,1.145-1.147V37.6h-.014v-.793A1.062,1.062,0,0,0,48.9,35.735H45.143a2.271,2.271,0,0,0-2.259-2.04Zm0,1.119a1.152,1.152,0,0,1,1.127.921H41.757A1.152,1.152,0,0,1,42.885,34.814ZM33.235,37.6a1.877,1.877,0,0,0-1.9,1.857V64.838a1.877,1.877,0,0,0,1.9,1.857h19.3a1.877,1.877,0,0,0,1.9-1.857V39.456a1.877,1.877,0,0,0-1.9-1.857H50.677v.411a1.858,1.858,0,0,1-1.9,1.818H37a1.858,1.858,0,0,1-1.9-1.818V37.6Zm14.174,5.823h2.674a.558.558,0,0,1,.536.581V62.086a.558.558,0,0,1-.536.581H47.409a.558.558,0,0,1-.536-.581V44A.558.558,0,0,1,47.409,43.422Zm-5.972,4.463h2.895a.435.435,0,0,1,.425.446V62.22a.435.435,0,0,1-.425.446H41.437a.435.435,0,0,1-.425-.446V48.331a.435.435,0,0,1,.425-.446Zm-5.8,3.689H38.4c.272,0,.491.149.491.335V62.332c0,.185-.219.335-.491.335H35.642c-.272,0-.491-.149-.491-.335V51.909C35.151,51.723,35.37,51.574,35.642,51.574Z" />
      </g>
    </svg>
  );
};

Stats.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Stats;
